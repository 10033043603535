import React from "react"
import styled, { css } from "styled-components"

// Styling
import { applyResponsive, Breakpoint } from "../styles/responsive"

// Components
import { GridCol, GridRow } from "./grid"
import Link from "./Link"
import noobwLogo from "../images/noobw-logo.svg"

const ArticleHeader: React.FCS = ({}) => (
  <Container>
    <GridCol s={1} m={2} mOffsetLeft={1} l={1} lOffsetLeft={1}>
      <StyledLink
        type="internal"
        icon="chevronLeft"
        caption="Back"
        iconColor="primary"
        iconSize={16}
        textStyle="subHeading"
        url="/"
      />
    </GridCol>
    <GridCol s={1} m={3} mOffsetRight={1} l={3} lOffsetRight={1}>
      <Link type="internal" url="/">
        <StyledImg src={noobwLogo} alt="Not On Our Border Watch logo" />
      </Link>
    </GridCol>
  </Container>
)

const Container = styled(GridRow)`
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 24px;

  ${applyResponsive(
    { from: Breakpoint.M },
    css`
      padding-top: 56px;
    `
  )}
`

const StyledLink = styled(Link)`
  > svg {
    ${applyResponsive(
      { from: Breakpoint.M },
      css`
        width: 24px;
        height: 24px;
      `
    )}
  }
`

const StyledImg = styled.img`
  margin: 0;
  width: 100%;
`

export default ArticleHeader
