import React from "react"
import styled, { css } from "styled-components"
import { graphql } from "gatsby"

// Utils
import { findBreakpoint } from "../utils/styleHelpers"

// Styling
import textStyles from "../styles/typography"
import colors from "../styles/colors"
import { applyResponsive, Breakpoint } from "../styles/responsive"

// Components
import Seo from "../components/Seo"
import ArticleHeader from "../components/ArticleHeader"
import Container from "../components/Container"
import { GridRow, GridCol } from "../components/grid"

// Types
interface IProps {
  data: any
}

export const query = graphql`
  query PrivacyStatement {
    prismicPrivacy {
      data {
        content {
          html
        }
      }
    }
  }
`

const PrivacyPage: React.FC<IProps> = ({ data }) => {
  const rawPageData = data.prismicPrivacy?.data ?? undefined

  const content = rawPageData.content.html

  return (
    <>
      <Seo title={"Privacy"} description={"Privacy statement for NOOBW"} />
      <ArticleHeader />
      <Container>
        <GridRow>
          <GridCol m={6} mOffsetLeft={2} l={6} lOffsetLeft={3}>
            {content && (
              <Content dangerouslySetInnerHTML={{ __html: content }} />
            )}
          </GridCol>
        </GridRow>
      </Container>
    </>
  )
}

const sharedImgStyles = css`
  margin: 0 ${findBreakpoint("s")?.gutterWidth! * -1}px 32px;

  ${applyResponsive(
    { from: Breakpoint.M },
    css`
      margin-left: 0;
      margin-right: 0;
    `
  )}
`

const Content = styled.article`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 40px;
    ${textStyles.subHeading}
  }

  a {
    color: ${colors.link};
  }

  p.block-img {
    ${sharedImgStyles}
  }

  img {
    width: calc(100% + ${findBreakpoint("s")?.gutterWidth! * 2}px);
  }

  ${applyResponsive(
    { from: Breakpoint.M },
    css`
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin-top: 56px;
      }

      img {
        width: 100%;
      }
    `
  )}
`

export default PrivacyPage
